import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUser } from "../services/users.service";
import { UpdateUserType } from "../types/users.types";

const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (user: UpdateUserType) => updateUser(user),
    onSuccess: () => {
      // TODO: This should be better awaited in the backend. This is just a workaround
      // to make sure the data is updated in the backend before refetching the data.
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: ["users"] });
      }, 2000);
    },
    onError: (_err, _update, ctx) => console.log("error", _err, _update, ctx),
  });
};

export default useUpdateUser;
