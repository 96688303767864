import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddRoomSettingsForm } from "./AddRoomSettingForm";
import useRoomsByStoreId from "../hooks/rooms/useRoomsById";
import useStores from "../../stores/hooks/useStores";
import { StoreSelector } from "../../stores/molecules/storeSelector";
import { CountrySelector } from "../../stores/molecules/countrySelector";
import { RoomSettingsTable } from "./RoomSettingsTable";
import { StoreData } from "../../stores/types/Stores.types";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import { RoomSetting } from "../types/rooms.types";
import { SearchBar } from "../molecules/SearchBar";
import { TableToolsHeader } from "../../layout/TableToolsHeader";
import { UserRole } from "../../../types/roles.types";
import { BlockRoomSettings } from "../molecules/BlockRoomSettings";

export const RoomSettingsByStore = () => {
  const navigate = useNavigate();
  const { storeNumber: storeNumberFromPath } = useParams();

  const { data: stores } = useStores();
  const { user } = useLoggedInUser();
  const storeNumber = storeNumberFromPath || user?.storeId;
  const { data: rooms } = useRoomsByStoreId(String(storeNumber));

  const [searchResults, setSearchResults] = useState<RoomSetting[]>([]);
  const [selectedStore, setSelectedStore] = useState<StoreData>({
    name: stores?.find((store) => store.storeId === storeNumber)?.name || "",
    store_id: storeNumber || "",
  });
  const [selectedCountry, setSelectedCountry] = useState<string>("SE");

  const handleSelectedStore = (storeId: string) => {
    const store = stores?.find((store) => store.storeId === storeId);

    if (!store) return;

    setSelectedStore({ name: store.name, store_id: store.storeId });
    navigate(`/roomsettings/store/${encodeURIComponent(store.storeId)}`);
  };

  const handleSearchResults = (results: RoomSetting[]) => {
    setSearchResults(results);
  };

  useEffect(() => {
    if (user) {
      setSelectedCountry(user.countryId);
    }
  }, [user]);

  useEffect(() => {
    const store = stores?.find((item) => item.storeId === storeNumber);
    if (store) {
      setSelectedStore({ name: store.name, store_id: store.storeId });
    }
  }, [stores, storeNumber]);

  useEffect(() => {
    if (rooms) {
      setSearchResults(rooms.rooms);
    }
  }, [rooms]);

  return (
    <BlockRoomSettings user={user}>
      <TableToolsHeader
        setShowTeamUsersTable={() => {}}
        showTeamUsersTable={false}
        selectedTeam={{ id: "", name: "" }}
        tableTitle="Room settings"
        tableSubtitle={`Your store: ${selectedStore?.name}`}
        headerControls={<AddRoomSettingsForm store={selectedStore} />}
        filtersLeft={
          <>
            {user && (
              <>
                <CountrySelector
                  selectedCountry={selectedCountry}
                  setCountry={setSelectedCountry}
                  showCountry={user.roleId === ("GLOBAL_SUPERUSER" as UserRole)}
                />
                {(["GLOBAL_SUPERUSER", "SUPERUSER", "COUNTRY_SUPERUSER"] as UserRole[]).includes(user.roleId) && (
                  <StoreSelector
                    stores={stores}
                    label="Store"
                    country={selectedCountry}
                    selectedStoreId={storeNumber}
                    setStoreId={handleSelectedStore}
                    firstOptionLabel="Select store"
                  />
                )}
              </>
            )}
          </>
        }
        filtersRight={
          <SearchBar
            id="room-search"
            items={rooms?.rooms || []}
            searchProps={["name"]}
            onSearchResults={handleSearchResults}
          />
        }
      />

      <RoomSettingsTable rooms={searchResults || { rooms: [] }} />
    </BlockRoomSettings>
  );
};
