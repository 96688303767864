import React from "react";
import Select, { Option } from "@ingka/select";
import { Store, StoreStatus } from "../types/Stores.types";

export interface CountryState {
  stores?: Store[];
  country: string;
  selectedStoreId?: string;
  label?: string;
  setStoreId: (stroreId: string) => void;
  firstOptionLabel?: string;
}

export const StoreSelector = ({
  stores,
  country,
  selectedStoreId,
  setStoreId,
  label,
  firstOptionLabel = "All stores",
}: CountryState) => {
  return (
    <Select
      id="stores"
      hintText={""}
      label={label}
      value={selectedStoreId}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
        const store = e.target.value;
        setStoreId(store);
      }}
    >
      <Option value="" label={firstOptionLabel} />
      {stores &&
        stores
          .filter((store) => store?.primaryLocale?.split("_")[1] === country && store.status === StoreStatus.ACTIVE)
          .sort((store1, store2) => (store1.name > store2.name ? 1 : store1.name < store2.name ? -1 : 0))
          .map((store) => <Option key={store.storeId} value={store.storeId} label={store.name} />)}
    </Select>
  );
};
